let HangUp, RenamePhoneFields, SetUpPhoneRows;
$(function() {
    $(document).on('click', '#destroy-client-submit', function(e) {
        e.preventDefault();
        if (confirm('I understand, under penalty of firing squad, that I have the authority to destroy this client and his/her related data. I accept all blame and anger from Barry if I am doing this when I should not be.')) {
            return $('#client-destroy-form').submit();
        }
    });

    $('.help').tooltip();

    $.ajaxSetup({"cache": "false"});

    $('#phone_add').click(function() {
        let display, label, number, ref;
        label = $('#phone_numbers_phone_label').val();
        number = $('#phone_numbers_phone').val();
        display = (ref = $('#phone_numbers_display_number').is(':checked')) != null ? ref : {
            "true": false
        };
        $('#phone-clone-row').clone().removeAttr('id').removeClass('hide').addClass('phonerow').appendTo('.phone-rows');
        $('.phone_label_field').last().val(label);
        $('.phone_number_field').last().val(number);
        $('.label-span').last().html(label);
        if (display || $('.phone-rows').children(':first:visible').length === 1) {
            $('.display_number_check').prop('checked', false).last(':visible').prop('checked', true);
        }
        RenamePhoneFields();
        SetUpPhoneRows();
        return HangUp();
    });

    $('.phone_close').click(function() {
        return HangUp();
    });

    $('.add-remove').click(function() {
        window.addRemoveMode();
        return $('#industry_selection').removeClass('hidden');
    });

    $('#industry_id').change(function() {
        window.addRemoveMode();
        $('#category_selection').removeClass('hidden');
        $('#client_selection').removeClass('hidden');
        $('#client_summary').removeClass('hidden');
        return $('#client_search').removeClass('hidden');
    });

    $("#migration-date-datetimepicker").datetimepicker({
        format: 'YYYY/MM/DD'
    });

    $(document).on('change', '#client_mailing_limit_attributes_set', function() {
        let val;
        val = $(this).is(':checked');
        if (val) {
            $('#client_mailing_limit_attributes_total').show();
        } else {
            $('#client_mailing_limit_attributes_total').hide();
        }
    });

    $(document).on('change', '#change-password-checkbox', function() {
        let val;
        val = $(this).is(':checked');
        if (val) {
            $('#client_user_attributes_password').removeAttr('disabled');
            $('#client_user_attributes_password_confirmation').removeAttr('disabled');
        } else {
            $('#client_user_attributes_password').attr('disabled', 'true');
            $('#client_user_attributes_password_confirmation').attr('disabled', 'true');
        }
    });
});

HangUp = function() {
    $('.phone_number_modal').val('');
    $('.phone_label_modal option:first-child').attr('selected', true);
    $('.phone_display_modal').removeAttr('checked');
    return $('#phone_modal').modal('hide');
};

RenamePhoneFields = function() {
    return $('.phonerow').each(function(i) {
        $(this).find('.phone_number_id_field').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][id]');
        $(this).find('.phone_label_field').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][phone_label]');
        $(this).find('.phone_number_field').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][phone]');
        $(this).find('.display_number_check').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][display_number]');
        $(this).find('.display_number_hidden').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][display_number]');
        return $(this).find('.phone_number_destroy_field').attr('name', 'client[user_attributes][phone_numbers_attributes][' + i + '][_destroy]');
    });
};

SetUpPhoneRows = function() {
    $('.display_number_check').on('change', function() {
        $('.display_number_check').prop('checked', true).not(this).prop('checked', false);
        return RenamePhoneFields();
    });
    $('.phone_number_delete').click(function() {
        if ($('.phonerow').not(':hidden').length > 1) {
            if ($(this).closest('.phonerow').find('.phone_number_destroy_field').length === 0) {
                $(this).closest('.phonerow').remove();
            } else {
                $(this).closest('.phonerow').hide();
                $(this).closest('.phonerow').find('.phone_number_destroy_field').val(1);
            }
        }
        if ($('.display_number_check:checked:visible').length === 0) {
            $('.display_number_check:visible:first').prop('checked', true);
        }
        return RenamePhoneFields();
    });
    $('.label-span').click(function() {
        return $(this).addClass('hide').prev().removeClass('hide').focus();
    });
    return $('.phone_label_field').blur(function() {
        return $(this).addClass('hide').next().removeClass('hide').html($(this).val());
    });
};

window.OutputEngagementTrendsChart = function(launch_dates, trends, elem_id) {
    let engagement_trends_chart = document.getElementById(elem_id);
    new Chart(engagement_trends_chart, {
        type: 'line',
        data: {
            labels: launch_dates,
            datasets: [
                {
                    label: 'Clicked',
                    data: trends,
                    fill: false,
                    borderColor: "#1dc9b7",
                    backgroundColor: "#1dc9b7"
                }
            ]
        },
        options: {
            legend: { display: true },
            tooltips: {
                callbacks: {
                    label: function(tool_tip_item, data) {
                        let label = data.datasets[tool_tip_item.datasetIndex].label || '';
                        let value = tool_tip_item.value || 0;
                        if (label) {
                            label += ": " + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                        }
                        return label;
                    }
                }
            }
        }
    });
}

window.OutputAnnualStatsChart = function (months, sents, opens, clicks, elem_id) {
    let chart_canvas = document.getElementById(elem_id);
    new Chart(chart_canvas, {
        type: 'bar',
        data: {
            labels: months,
            datasets: [
                {
                    label: 'Sent',
                    data: sents,
                    borderColor: "#5578eb",
                    backgroundColor: "#5578eb"
                },
                {
                    label: 'Total Opened',
                    data: opens,
                    borderColor: "#ffb822",
                    backgroundColor: "#ffb822"
                },
                {
                    label: 'Clicked',
                    data: clicks,
                    borderColor: "#1dc9b7",
                    backgroundColor: "#1dc9b7"
                }
            ]
        },
        options: {
            legend: { display: true },
            tooltips: {
                callbacks: {
                    label: function(tool_tip_item, data) {
                        let label = data.datasets[tool_tip_item.datasetIndex].label || '';
                        let value = tool_tip_item.value || 0;
                        if (label) {
                            label += ": " + value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                        }
                        return label;
                    }
                }
            }
        }
    })
};

window.addRemoveMode = function() {
    if ($('[name=add_remove]:checked').val() === 'Remove') {
        $('[name*=selected_categories]').prop('type', 'radio');
        $('#category_text').html('<h5>Select a category to remove</h5>');
        $('#client-categories-save-btn').html('Remove Categories');
        return $('#form_add_remove').val('Remove');
    } else {
        $('[name*=selected_categories]').prop('type', 'checkbox');
        $('#category_text').html('<h5>Select categories to add</h5>');
        $('#client-categories-save-btn').html('Add Categories');
        return $('#form_add_remove').val('Add');
    }
};
